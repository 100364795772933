import { useCallback, useEffect, useState } from 'react';
import PageLoader from 'components/spinner/PageLoader';
import type { ApiDataForSurvey } from 'types/apiTypes';
import { retrieveDataForSurvey } from 'services/survey';
import { LocationValidation } from './LocationValidation';
import { SurveyFormProps } from './SurveyTypes';
import SurveyUtils from './SurveyUtils';
import { useLocationVerification, LocationVerificationStatus } from './useLocationVerification';
import Survey from './Survey';
// Removing temporrary because it messes with embedding - the header is
// displayed on top of the page instead of the in the background
// import MiniHeader from './MiniHeader';

export default function SurveyContainer(props: SurveyFormProps): JSX.Element {
  const { clazz, slugId, projectConfiguration, match, location } = props;
  const pollId = match.params.id;

  const [apiData, setApiData] = useState<ApiDataForSurvey | null>(null);
  const isLocationVerified = useLocationVerification(projectConfiguration?.domain?.isActivated || false, slugId);

  /**
   * Close poll
   */
  const closePoll = useCallback(() => {
    SurveyUtils.closePoll(projectConfiguration?.customizations, location);
  }, [projectConfiguration?.customizations, location]);

  /**
   * Load data from API
   */
  useEffect(() => {
    if (!(slugId && pollId)) return () => {};

    // This is for backward compatibility
    // is-modal-opened on <body> is requred for EMBEDDING styling to function properly
    // with autoheight=true
    // see https://github.com/sdesregistry/IH-Engage/issues/2375
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('is-modal-opened');

    SurveyUtils.announcePageLoading('Loading survey.');

    retrieveDataForSurvey(slugId, pollId)
      .then((data) => {
        // check the survey is active/open
        const isSurveyActive = data.surveyList.polls.some((item) => item._id === pollId);
        if (!isSurveyActive) {
          closePoll();
        }

        setApiData(data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error:', err);
      });

    // remove class from body on component unmount. see above
    return () => {
      body.classList.remove('is-modal-opened');
    };
  }, [pollId, slugId, closePoll]);

  /**
   * Hook global ESC handler to close poll
   */
  useEffect(() => {
    function handleEscape(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        SurveyUtils.closePoll(projectConfiguration?.customizations, location);
      }
    }
    // Add event listener to the document
    document.addEventListener('keydown', handleEscape);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [location, projectConfiguration?.customizations]);

  return (
    <>
      {/* <MiniHeader projectHeader={apiData?.about?.projectHeader} /> */}
      <div aria-live='assertive' className={`l-app ${clazz}`}>
        {apiData && isLocationVerified === LocationVerificationStatus.Verified ? (
          <Survey {...props} apiData={apiData} />
        ) : null}
        <LocationValidation
          isLocationVerified={isLocationVerified}
          locationName={apiData?.about.locationName}
          clazz={clazz}
          closePoll={closePoll}
        />
        {isLocationVerified === LocationVerificationStatus.Pending ? <PageLoader /> : null}
      </div>
    </>
  );
}

/**
 * LEFT HERE FOR REFERENCE TO OLD SOLUTION
 * ESC key handler for closing poll, wrapped in useCallback
 * https://reactjs.org/docs/hooks-reference.html#usecallback
 * NOW better solution that works even if the survey window is not selected and
 * doesn't mess with tabindex
 */
// const onOverlayKeyDown = useCallback(
//   (event: React.KeyboardEvent) => {
//     if (event.key === 'Escape') {
//       SurveyUtils.closePoll(projectConfiguration?.customizations, location);
//     }
//   },
//   [location, projectConfiguration?.customizations],
// );
// eslint-disable-next-line jsx-a11y/no-static-element-interactions
// <div aria-live='assertive' onKeyDown={onOverlayKeyDown} tabIndex={-1} className={`l-app ${clazz}`}>
