// To set server in terminal export REACT_APP_SERVER=http://localhost:9000/engage-api
// DEVELOP
// export const SERVER = process.env.REACT_APP_SERVER || `https://api-develop.givemyview.com/gmv-api`;
// export const SERVER = process.env.REACT_APP_SERVER || `https://api-staging.givemyview.com/gmv-api`;
// export const SERVER = process.env.REACT_APP_SERVER || `https://bid-engage-develop.herokuapp.com/engage-api`;
// AUX SERVER
// https://gmv-aux-uk.herokuapp.com/gmv-api

export const version = `1.80.8`;
export const SERVER = process.env.REACT_APP_SERVER || `http://localhost:9000/gmv-api`;
export const SERVER_V1 = `${SERVER}/v1`;
export const SERVER_V2 = `${SERVER}/v2`;
// export const SERVER = process.env.REACT_APP_SERVER || `https://api-develop.givemyview.com/gmv-api/v1`;
// export const SERVER = process.env.REACT_APP_SERVER || `https://api-staging.givemyview.com/gmv-api/v1`;
// export const SERVER = process.env.REACT_APP_SERVER || `https://bid-engage-develop.herokuapp.com/engage-api/v1`;

export const GAKEY = process.env.REACT_APP_GA_KEY || `UA-60277136-4`;

export const FB_PIXEL = process.env.REACT_APP_FB_PIXEL || '624695611806959';
export const RECAPTCHA = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeZ-78ZAAAAAPZsB3ZHX3prMyQZ_9v9eJIvV36E';
// Delay for button animation on question submit
export const SUBMIT_DELAY = 2000;

// Other choice image
// Fix Sax the image showing ok on normal pages/browsers,
// but braking with strange 400 Error from S3 when generating PDF (puppeteer)
// 400 https://s3.amazonaws.com/cdn.built-id.com/static/images/choice-other-wide.png
// ERR Failed to load resource: the server responded with a status of 400 (Bad Request)
export const OTHER_CHOICE_IMAGE = `https://d3h3sugvwhjhl.cloudfront.net/static/images/choice-other-wide.png`;
// export const OTHER_CHOICE_IMAGE = `https://s3.amazonaws.com/cdn.built-id.com/static/images/choice-other-wide.png`;

// Terms&Conditions and PP pages link
export const TERMS_AND_CONDITIONS_LINK = '/terms-and-conditions';
export const PRIVACY_POLICY_LINK = '/privacy-policy';

// export const BASE_IMAGE_URL = 'https://d166l4t6lwjt0e.cloudfront.net/static/media';
export const BASE_IMAGE_URL = 'https://d3h3sugvwhjhl.cloudfront.net/static/media';
export const REACTION_BASE_IMAGE_URL = 'https://d3h3sugvwhjhl.cloudfront.net/static/assets/reactions';

// MAP_KEY for location on project homepage
export const MAP_KEY = 'AIzaSyBGoXwhUyFB-jVJsOCRoF8lmGevt0WVvCk';

export const SECONDS_PER_QUESTION = 20;

// MAX post code length
export const MAX_POSTCODE_LENGTH = 10;
export const MAX_FEEDBACK_SUBJECT_CHARS = 100;
export const MAX_FEEDBACK_CHARS = 2000;
export const CHAR_LIMIT_WARNING = 10;
export const MAX_QUICK_TEXT_LENGTH = 2000;

// powered by button in question header
export const POWERED_BY_LINK = 'https://www.built-id.com/give-my-view/landing/powered-by';
export const FEATURE_FLAG_POWERED_BY = true;
